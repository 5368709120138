@mixin statusBarSegment {
  border-top: 1px solid black;
  padding: 10px;
}

.profile-body {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  &--content {
    width: 100%;
    padding: 10px;
    border-left: 1px solid black;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    &-inputs {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .input-component {
        width: 350px;
        margin-bottom: 20px;
        &--label {
          font-size: 1.6em;
          top: -5px;
          left: -25px;
        }
      }
    }
    &--buttons {
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .button-component {
        max-width: 300px;
      }
    }
  }
}

@media (max-width: 768px) {
  .profile-body {
    &--content {
      &--buttons {
        flex-direction: column;
      }
    }
  }
}
