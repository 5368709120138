.price-category-wrapper {
  width: 80%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
}
.price-input {
  position: relative;
  &-info {
    position: absolute;
    top: 32px;
    right: 16px;
    font-size: 1.2em;
  }
}
