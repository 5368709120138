.provider-image-administration {
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;

  &--slideshow {
    max-width: 600px;
  }
  &--provider,
  &--list {
    &-image--header {
      display: flex;
      flex-direction: row;
      align-items: center;
      .p-headline {
        margin: 5px;
      }
      .info-component--content {
        max-height: none !important;
        &-text {
          margin-top: -5px !important;
        }
        &-arrow {
          display: none;
        }
      }
    }
  }
  &--images {
    &--list-item {
      user-select: none;
      display: flex;
      align-items: center;

      .name {
        flex-grow: 1;
      }

      .icon {
        svg {
          cursor: pointer;
          height: 20px;
          width: 20px;
          color: red;
        }
      }
    }

    &-add-icon {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }
}
