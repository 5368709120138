.provider-wrapper--progressbar {
  width: 200px;
  margin-right: 20px;
  margin-left: 20px;
}

.in-one-row {
  display: flex;
  flex-wrap: wrap;

  .provider-entry-wrapper {
    margin-right: 10px;
  }
}

.provider-entry-wrapper {
  &.licence {
    width: 100%;
    max-width: 400px;
  }
  &--buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > .button-component,
    > .confirm-popup-component-wrapper {
      max-width: 160px;
    }
  }
  &--headline {
    font-weight: bold;
  }

  &--content {
    margin-bottom: 20px;

    .tags-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.confirm-popup-component-wrapper {
  .button-component {
    padding: 2% 1% 2% 1%;
  }
}
