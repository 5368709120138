.dashboard-admin {
  &--jump-wrapper {
    display: flex;
    flex-direction: row;
    max-width: 1180px;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;

    &--links-wrapper {
      padding: 10px 20px;
      box-sizing: border-box;
      width: 50%;
      margin-right: 30px;
      display: flex;
      flex-direction: column;
      height: 300px;
      box-shadow: 2px 2px 4px #00000029;
      border-radius: 30px;
      margin-top: 20px;

      .title {
        font-size: 1.2em;
        flex-grow: 1;
      }

      .item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1.2em;

        svg {
          height: 60px;
        }
      }
    }

    &--clickbox-wrapper {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      width: 50%;
      margin: 0 auto;
      box-sizing: border-box;
      margin-top: 20px;
    }
  }

  &--header {
    width: 100%;
    padding: 80px 0;
    position: relative;
    margin-bottom: 260px;

    &-info-wrapper {
      overflow-x: scroll;
      position: absolute;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      .info-box-component {
        min-width: 200px;
        height: 300px;
        padding: 10px;
        text-align: center;

        &--topBar-text {
          text-align: left;
        }

        * p {
          text-align: left;
          font-size: 0.9em;
          margin-bottom: 10px;
        }

        * svg {
          height: 80px;
        }
      }
    }

    &-wrapper {
      box-sizing: border-box;
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      > div {
        p {
          font-size: 2em;
        }
      }
    }
  }
}
