body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh;
  #root {
    height: 100%;
  }
}

* {
  margin: 0;
  padding: 0;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  > div {
    width: 49%;
    min-width: 0px;

    * {
      min-width: 0px;
    }
  }
}

.default-page-headline {
  z-index: 5;
  position: fixed;
  top: 90px;
  width: 100vw;

  h1 {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
}

.default-page-wrapper {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 100px;
}

.flex-it {
  display: flex;
  &-wrap {
    display: flex;
    flex-wrap: wrap;

    .gap-distance {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  &-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &-between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.half-the-width {
  width: 49%;
  min-width: 250px;
}

.split-width {
  width: 49%;
}

.highlightColorHeader {
  margin: -65px -20px 0px -20px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 58px 20px 20px 20px;
}

.add-a-liltle-bit-margin-top {
  margin-top: 40px;
}

.trash-wrapper {
  cursor: pointer;
  svg {
    color: red;
    height: 20px;
  }

  &.black-color {
    svg {
      color: black;
    }
  }

  &.deactivated {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.table-svg-wrapper {
  &.green {
    color: green;
  }
  &.red {
    color: red;
  }
  svg {
    height: 20px;
  }
  &-clickable {
    cursor: pointer;
    svg {
      height: 20px;
    }
  }
}

.flex-grow-one {
  flex-grow: 1;
}

.little-bit-margin-right {
  margin-right: 5px;
}

.p-headline {
  font-weight: bold;
  margin-bottom: 10px;
}

.selectButton {
  padding: 20px;
  border: 1px solid black;
  border-radius: 25px;
  box-sizing: "border-box";
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  margin: 5px;

  &:hover,
  &.active {
    background: black;
    color: white;
  }
}

input[type="time"] {
  width: 120px !important;
  border: none;
  clip-path: inset(0 17px 0 0);
  outline: none;
  outline: 0;
}

.provider-edit-popup {
  .holiday-list {
    flex-direction: column;
  }

  .holiday-item {
    width: 100%;
    justify-content: space-between;
  }
  .popup-component--wrapper {
    &-content-wrapper {
      max-width: 100% !important;
    }
  }
}

.lunch-edit-page {
  box-sizing: border-box;
  &--header {
    width: 100%;
    height: 15dvh;
    display: flex;
    align-items: flex-end;
    padding-bottom: 2rem;
    padding-left: 6%;
    @media screen and (max-width: 800px) {
      display: none;
    }
    &--headline {
      font-size: 40px;
      font-weight: 300;
    }
  }
  &--content {
    display: flex;
    min-height: 80dvh;
    height: fit-content;
    &--step-wrapper {
      width: 22%;
      @media screen and (max-width: 800px) {
        width: 100%;
        height: fit-content;
        min-height: 80dvh;
      }
      display: flex;
      gap: 1rem;
      flex-direction: column;
      padding-top: 1rem;
      &--step {
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding: 15px 10px 5px 25px;
        background-color: white;
        border: 2px solid black;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        min-height: 12dvh;
        height: fit-content;
        &.completed {
          background-color: rgb(219, 233, 216);
          border: none;
        }
        @media screen and (max-width: 800px) {
          width: 80%;
        }
        &--headline {
          font-weight: bold;
        }
        &--text {
          color: #a0a0a0;
        }
      }
      &--button-wrapper {
        width: 75%;
        margin: 1rem auto;
      }
    }
    &--content-wrapper {
      width: 78%;
      display: flex;
      @media screen and (max-width: 800px) {
        display: none;
      }
      &--nav-button-wrapper {
        display: flex;
        padding: 1rem;
        justify-content: center;
        gap: 2rem;
        div {
          max-width: 200px;
        }
      }
      &--step-wrapper {
        display: flex;
        flex-direction: row;
        height: fit-content;
      }
      &--placeholder {
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 80%;
        height: 80%;
        &--icon {
          width: 500px;
          margin: 0 auto;
        }
        &--headline {
          width: 100%;
          text-align: center;
          font-size: 1.3rem;
          font-weight: bold;
        }
        &--text {
          text-align: center;
          width: 50%;
          margin: 0 auto;
          font-size: 1.1rem;
        }
      }
    }
  }
}
.step-number {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  border: 3px solid black;
  left: -10px;
  top: 6dvh;
  text-align: center;
  background-color: white;
  border-radius: 50%;
  font-weight: bold;
}
.step-check {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  left: -10px;
  top: 5.5dvh;
  text-align: center;
  background-color: white;
  border-radius: 50%;
  font-weight: bold;
  color: rgb(155, 209, 160);
}
.lunch-edit-step-2,
.lunch-edit-step-1 {
  &--wrapper {
    max-width: 800px;
    height: 100%;
    width: 100%;
    margin-top: 4rem;
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (max-width: 800px) {
      margin-top: 0;
      padding-left: 0;
    }
  }
  &--add-meal {
    border: 1px solid lightgray;
    border-radius: 25px;
    padding: 1rem;
    box-shadow: 2px 3px 1px lightgrey;
    transition: background-color 0.5s;
    &--headline {
      display: flex;
      gap: 1rem;
      align-items: center;
      font-size: 1.3rem;
    }
    &--icons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      &--check-icon {
        width: 18px;
        height: 18px;
        padding: 12px;
        border-radius: 50%;
        color: white;
        background-color: rgb(155, 209, 159);
      }
      &--delete-icon {
        width: 18px;
        height: 18px;
        padding: 12px;
        border-radius: 50%;
        color: white;
        background-color: rgb(220, 145, 142);
      }
    }
  }
  &--meal-overview {
    border: 1px solid lightgray;
    border-radius: 25px;
    padding: 1rem;
    box-shadow: 2px 3px 1px lightgrey;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &--headline-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
      &--headline {
        font-size: 1.2rem;
      }
    }
  }
  &--meal-icons {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;

    &--trash {
      color: rgb(226, 166, 164);
    }
  }
  &--description,
  &--archive,
  &--delivery,
  &--days,
  &--week,
  &--time {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  &--headline {
    &--wrapper {
      display: flex;
      gap: 1.5rem;
      align-items: center;
      @media screen and (max-width: 800px) {
        justify-content: space-between;
      }
    }
    &--text {
      font-size: 1.2rem;
    }
    &--icon {
      height: 15px;
      width: 15px;
      padding: 3px;
    }
  }
  &--archive-headline-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 1rem;
    font-size: 1.2rem;
  }

  &--dropdown-wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
  &--dropdown-label {
    font-weight: 400;
    padding-bottom: 0.5rem;
    margin-left: 0;
  }
  &--delivery-input-wrapper,
  &--name-input-wrapper,
  &--description-input-wrapper,
  &--price-input-wrapper,
  &--time-input-wrapper {
    gap: 0.3rem;
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    &--input {
      display: flex;
      align-items: center;
    }
  }
  &--day-info {
    margin-left: 2rem;
    padding: 1rem 0;
  }
  &--day-select-wrapper {
    display: flex;
    margin-left: 2rem;
    gap: 0.8rem;
    &--day {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &--label {
        text-align: center;
      }
    }
  }
  &--tag-wrapper {
    display: flex;
    height: fit-content;
    align-items: center;
    gap: 1rem;
    align-items: flex-start;
    padding-top: 1rem;
    @media screen and (max-width: 800px) {
      width: 100%;
      align-items: center;
      gap: 0.5rem;
    }
    &--list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;
      &--entry-wrapper {
        &.big {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }
    }
  }
  &--tag {
    width: 10%;
    min-width: fit-content;
    border: 1px solid black;
    padding: 0.3rem;
    margin: 0.4rem;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    &--day-tag {
      &--wrapper {
        display: flex;
        flex-wrap: wrap;
        font-size: 0.8rem;
        gap: 10px;
      }
    }
    &--list-tag {
      width: 10%;
      min-width: fit-content;
      border: 1px solid black;
      padding: 0.3rem;
      margin: 0.4rem;
      border-radius: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        color: white;
        background-color: black;
      }
    }
    &.selected {
      color: white;
      background-color: black;
    }
    &::first-letter {
      text-transform: capitalize;
    }
  }
  &--popup {
    &--tag-wrapper {
      height: fit-content;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.meal-tag {
  width: fit-content;
}
.hide-on-big {
  @media screen and (min-width: 801px) {
    display: none;
  }
}
.hide-on-small {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.lunch-edit-table {
  &--items {
    font-size: 0.8rem;
    &--2 {
      color: lightgrey;
    }
  }
  &--copy-wrapper {
    border-bottom: 1px solid black;
    width: fit-content;
    cursor: pointer;
  }
  &--date {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }
}
