.confirm-popup-component-wrapper {
  // import from .button-component
  font-family: "DM Sans", "Avenir Next", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  padding: 2% 1% 2% 1%;
  width: 100%;
  position: relative;

  > .button-component {
    all: unset;
  }

  .popup-component--wrapper-content-wrapper {
    width: fit-content;
    height: fit-content;
    min-width: 300px;
    border-radius: 30px;
    margin: 5vh 5vw;
  }
  .content {
    text-align: center;
    font-size: 1.2em;
  }
}
