.admin-configuration-wrapper {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  &--map-customization {
    width: 80%;
    margin-left: 10%;
    &-text {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 30px;
      font-weight: bold;
    }
    &-map {
      height: 450px;
      .map-wrapper {
        height: calc(100% - 30px);
      }
      &-coords {
        height: 30px;
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
    &-save {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .button-component {
        max-width: 400px;
      }
    }
    &-input {
      .flex-it {
        width: 100%;
      }
      .column {
        flex-direction: column;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      .button-component {
        max-width: 400px;
      }
    }
  }
  &--icon-wrapper {
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--add-icon {
    cursor: pointer;
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }

  .popup-component--wrapper {
    &-content-wrapper {
      height: fit-content;
    }
  }

  .retractable-component--header--title {
    flex-grow: 1;
  }

  .slideshow-component {
    box-shadow: 2px 2px 4px #00000029;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

.social-media-item-config {
  display: flex;
  align-items: center;

  svg {
    height: 30px;
  }
}

.slideshow-item-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  padding-right: 40px;

  &--arrowDown {
    transform: rotate(90deg);
    height: 30px;
  }

  &--arrowUp {
    transform: rotate(-90deg);
    height: 30px;
  }

  &--trash {
    flex-basis: 4%;
    margin: 0 10px;
  }

  &--trash-icon {
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    flex-basis: 10%;
    z-index: 1;
    path {
      fill: red;
    }
  }

  &--index {
    font-weight: bold;
    margin-right: 10px;
  }

  &--title {
    flex-grow: 1;
  }
}

@media (max-width: 768px) {
  .admin-configuration-wrapper {
    padding: 10px;
    &--map-customization {
      width: 100%;
      margin-left: 0px;
    }
  }
}
