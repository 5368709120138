.dashboard-provider {
  &--back-to-top {
    display: none;
    width: 100%;
    justify-content: center;
    padding-top: 40px;
    box-sizing: border-box;

    a {
      color: black;
      &:hover {
      }
    }
  }

  &--clickbox-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: calc(100% - 20px);
    max-width: 1180px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 20px;

    .click-box-wrapper {
      width: 48%;
    }
  }

  &--header {
    width: 100%;
    padding-top: 80px;
    position: relative;

    &--background {
      height: 250px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }

    &-info-wrapper {
      width: 90%;
      left: 50%;
      max-width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: row;
      margin: 0 auto;

      .info-box-component {
        min-width: 200px;
        width: 250px;
        height: 300px;
        padding: 10px;
        text-align: center;

        &--topBar-text {
          text-align: left;
        }
        &--content {
          p {
            text-align: left;
            font-size: 0.9em;
            margin-bottom: 10px;
          }
          svg {
            height: 160px;
            overflow: hidden;
            margin-top: -20px;
          }
        }
      }
    }

    &-wrapper {
      box-sizing: border-box;
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      > div {
        p {
          font-size: 2em;
          padding: 0 20px;
          box-sizing: border-box;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboard-provider {
    &--back-to-top {
      display: flex;
    }
    &--clickbox-wrapper {
      flex-direction: column;

      .click-box-wrapper {
        max-width: 420px;
        margin: 0 auto;
        width: 100%;
        min-width: 0px;
      }
    }
    &--header-info-wrapper {
      .info-box-component {
        max-width: 400px;
        width: 100%;
        min-width: 0px;
      }
    }
  }
}
