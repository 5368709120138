@import "./typography.scss";

.success-box {
  @include font-defaults();
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: #9bd3e5;
  &--image {
    width: 45%;
    background-image: url("../assets//images/successScribble.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  &--text {
    padding: 50px;
    width: 55%;
    font-size: 2em;
    &-header {
      margin-bottom: 20px;
    }
    &-content {
      margin-bottom: 20px;
      * {
        margin-bottom: 50px;
      }
    }
    &-buttons {
      width: 100%;
      .dashboard-button {
        width: 400px;
      }
    }
  }
}

@media (max-width: 768px) {
  .success-box {
    flex-direction: column;
    align-items: center;
    &--text {
      font-size: 1em;
      width: calc(100% - 100px);
      height: calc(100% - 100px);
      &-content {
        * {
          margin-bottom: 30px;
        }
      }
      &-buttons {
        .dashboard-button {
          width: 100%;
        }
      }
    }
    &--image {
      width: 0px;
      height: 0px;
    }
  }
}
