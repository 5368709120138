.description-text-retractable {
  margin-bottom: 10px;
}

#provider-configuration-wrapper {
  .padding-up {
    padding-top: 50px;
  }

  .padding-down {
    padding-bottom: 10px;
  }

  .dropdown-component {
    margin: 0;
  }

  .button-component {
    padding: 0;
  }

  .flex-space-between {
    justify-content: space-between;
  }

  .holiday-list {
    flex-direction: column;
  }

  .holiday-item {
    width: 100%;
    justify-content: space-between;
  }

  .popup-max-width {
    .popup-component--wrapper-content-wrapper {
      max-width: 500px;

      @media (max-width: 768px) {
        max-width: none;
      }
    }
  }
}

.download-image-wrapper {
  display: flex;

  &-item {
    &.remove {
      color: red;
    }
    svg {
      cursor: pointer;
      height: 20px;
      margin-right: 10px;
    }
  }
}

@media (max-width: 768px) {
  .configuration-data-wrapper {
    .half-the-width {
      width: 100% !important;
    }
  }
}

.slideshow-component--content-item-text {
  .button-component {
    .slideshow-item-buttton-wrapper {
      justify-content: flex-start !important;
      padding: 0;
    }
  }
}
