@import "./typography.scss";

.legal-page-wrapper {
  font-family: $font-family;
  padding: 20px;
  box-sizing: border-box;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;

  p {
    margin: 20px 0;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}
