@import "../styles/typography.scss";

.signup-box {
  position: relative;
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;

  &--counter {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;

    &.selected-sgnup-box {
      background: black;
      color: white;
    }

    &.everything-good {
      border: 2px solid #8bd39b;
      background-color: #8bd39b;

      svg {
        height: 80%;

        path {
          fill: white;
        }
      }
    }
  }

  &--content {
    margin-left: 15px;
    border-radius: 30px;
    padding: 20px;
    padding-left: 25px;
    padding-bottom: 40px;
    box-sizing: border-box;
    border: 2px solid black;
    background-color: white;

    &.everything-good {
      border: 2px solid #8bd39b;
      background-color: #d7ead7;
    }

    .unselected-content {
      p {
        margin-top: 8px;
        color: #a0a0a0;
        margin-bottom: 10px;
      }
      .openingtimes-result-entry {
        p {
          font-weight: bold;
          color: black;

          &.value-item {
            margin-top: -12px;
            font-weight: normal;
          }
        }
      }
    }

    .selected-content {
      .flex-it {
        justify-content: space-between;
      }

      .different-day-wrapper--item {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        &--add {
          z-index: 1;

          margin-left: 20px;
          min-height: 20px;
          min-width: 20px;
          max-height: 20px;
          max-width: 20px;
          height: 20px;
          width: 20px;
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;

            svg {
              path {
                fill: #e4e4e4;
              }
            }
          }
        }

        &--day {
          height: 35px;
          min-height: 35px;
          max-height: 35px;
          width: 35px;
          max-width: 35px;
          min-width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid black;
          border-radius: 50%;
          cursor: pointer;
          user-select: none;

          &:focus {
            outline: none;
            user-select: none;
          }

          &.active {
            background: black;
            color: white;
          }
        }
      }

      .time-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        &--item {
          align-items: center;
          display: flex;

          .seperator {
            margin: 0 10px;
          }

          input[type="time"] {
            width: 80px !important;
          }
        }
        .input-component {
          width: 70px;
          text-align: center;
        }
        &--add {
          svg {
            height: 20px;
            min-height: 20px;
            max-height: 20px;
            width: 20px;
            max-width: 20px;
            min-width: 20px;
            margin-left: 20px;
            cursor: pointer;
          }

          &.disabled {
            svg {
              cursor: not-allowed;

              path {
                fill: #e4e4e4;
              }
            }
          }
        }
      }

      .day-of-week-selector-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &--item {
          margin: 5px;
          height: 35px;
          min-height: 35px;
          max-height: 35px;
          width: 35px;
          max-width: 35px;
          min-width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid black;
          border-radius: 50%;
          cursor: pointer;
          user-select: none;

          &:focus {
            outline: none;
            user-select: none;
          }

          &.active {
            background: black;
            color: white;
          }
        }
      }

      .input-component {
        min-width: 0px !important;
        &--input {
          min-width: 0px !important;
          padding-left: 0;
          &::-webkit-calendar-picker-indicator {
            display: none;
          }
          &--underline {
            margin: 0;
            width: 100%;
          }
        }
      }
      .agb-wrapper {
        display: flex;
        align-items: center;
        width: 90%;
        margin: 0 auto;

        a {
          color: black;
        }

        .checkbox-component {
          margin-right: 0;

          &--label {
            width: 0;
            padding: 0;
            margin: 0;
          }
        }
      }

      .button-component {
        margin: 0 auto;
        max-width: 300px;
      }
    }

    .category-selector-wrapper {
      margin: 20px 0;
      display: flex;
      align-items: flex-start;
      &--input {
        margin-right: 10px;

        input {
          accent-color: black;
          outline: none;
        }
      }
      &--text {
        &-header {
          font-weight: bold;
        }
      }
    }
  }
}

.new-signup-content-wrapper {
  overflow-y: auto;
  @include font-defaults;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  min-height: calc(100%);
  height: 100vh;
  width: 100%;
  box-sizing: border-box;

  &--top {
    box-sizing: border-box;
    width: 100%;
    height: calc(100%);
    flex-grow: 1;
    display: flex;

    .mobile-wrapper {
      overflow-y: auto;
      max-width: 400px;
      width: 100%;
      max-height: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .header {
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &--header {
          font-size: 20px;
          font-weight: normal;
        }

        svg {
          width: 90%;
          margin: 0 auto;
          max-width: 140px;
        }

        &--title {
          font-size: 20px;
          width: 70%;
          margin: 0 auto;
          font-weight: bold;
          text-align: center;
        }

        &--text {
          font-size: 20px;
          width: 70%;
          margin: 0 auto;
          margin-top: 10px;
          margin-bottom: 10px;
          text-align: center;
        }
      }

      .content {
        margin-bottom: 10px;
        flex-grow: 1;

        .agb-wrapper {
          display: flex;
          align-items: center;
          width: 90%;
          margin: 0 auto;

          a {
            color: black;
          }

          .checkbox-component {
            margin-right: 0;

            &--label {
              width: 0;
              padding: 0;
              margin: 0;
            }
          }
        }

        .button-component {
          margin: 0 auto;
          max-width: 300px;
        }
      }
    }

    .desktop-wrapper {
      padding: 20px;
      flex-grow: 1;
      display: flex;
      justify-content: center;

      &--content {
        padding-top: 60px;
        box-sizing: border-box;
        height: 100%;
        overflow-y: auto;
        width: 90%;
        max-width: 600px;
        margin: 0 auto;

        .desktop-menu-button-wrapper {
          margin-bottom: 30px;
          display: flex;
        }

        .header {
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          &--header {
            font-size: 20px;
            font-weight: normal;
          }

          svg {
            width: 90%;
            margin: 0 auto;
            max-width: 140px;
          }

          &--title {
            font-size: 20px;
            width: 70%;
            margin: 0 auto;
            font-weight: bold;
            text-align: center;
          }

          &--text {
            font-size: 20px;
            width: 70%;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: center;
          }
        }

        .category-selector-wrapper {
          margin: 20px 0;
          display: flex;
          align-items: flex-start;
          &--input {
            margin-right: 10px;

            input {
              accent-color: black;
              outline: none;
            }
          }
          &--text {
            &-header {
              font-weight: bold;
            }
          }
        }

        .selected-content {
          .agb-wrapper {
            display: flex;
            align-items: center;
            width: 90%;
            margin: 0 auto;

            a {
              color: black;
            }

            .checkbox-component {
              margin-right: 0;

              &--label {
                width: 0;
                padding: 0;
                margin: 0;
              }
            }
          }

          .button-component {
            margin: 0 auto;
            max-width: 300px;
          }
          .desktop-description-wrapper {
            margin-bottom: 40px;
          }

          .flex-it {
            justify-content: space-between;
          }

          .different-day-wrapper--item {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            &--add {
              margin-left: 20px;
              min-height: 20px;
              min-width: 20px;
              max-height: 20px;
              max-width: 20px;
              height: 20px;
              width: 20px;
              cursor: pointer;
              z-index: 1;

              &.disabled {
                cursor: not-allowed;

                svg {
                  path {
                    fill: #e4e4e4;
                  }
                }
              }
            }

            &--day {
              height: 35px;
              min-height: 35px;
              max-height: 35px;
              width: 35px;
              max-width: 35px;
              min-width: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 2px solid black;
              border-radius: 50%;
              cursor: pointer;
              user-select: none;

              &:focus {
                outline: none;
                user-select: none;
              }

              &.active {
                background: black;
                color: white;
              }
            }
          }

          .time-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            &--item {
              align-items: center;
              display: flex;

              input[type="time"] {
                width: 80px !important;
              }

              .seperator {
                margin: 0 10px;
              }
            }
            .input-component {
              width: 70px;
              text-align: center;
            }

            &--add {
              svg {
                height: 20px;
                min-height: 20px;
                max-height: 20px;
                width: 20px;
                max-width: 20px;
                min-width: 20px;
                margin-left: 20px;
                cursor: pointer;
              }

              &.disabled {
                svg {
                  cursor: not-allowed;

                  path {
                    fill: #e4e4e4;
                  }
                }
              }
            }
          }

          .day-of-week-selector-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &--item {
              margin: 5px;
              height: 35px;
              min-height: 35px;
              max-height: 35px;
              width: 35px;
              max-width: 35px;
              min-width: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 2px solid black;
              border-radius: 50%;
              cursor: pointer;
              user-select: none;

              &:focus {
                outline: none;
                user-select: none;
              }

              &.active {
                background: black;
                color: white;
              }
            }
          }

          .input-component {
            min-width: 0px !important;
            &--input {
              min-width: 0px !important;
              padding-left: 0;
              &::-webkit-calendar-picker-indicator {
                display: none;
              }
              &--underline {
                margin: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 0px 20px 20px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    &--link {
      text-align: right;
    }

    &--item {
      font-size: 16px;

      a {
        color: black;
      }
    }
  }
}

@media (max-width: 700px) {
  .new-signup-content-wrapper--top .mobile-wrapper .content {
    margin-top: 10px;
  }

  .new-signup-content-wrapper--top .mobile-wrapper {
    max-width: 9999px;
  }

  #desktop-wrapper {
    display: none;
  }
}
