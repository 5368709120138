@import "./typography.scss";

.headline-profile-configuration {
  display: none;
  .provider-config-mobile-buttons {
    display: flex;
    margin-right: 10px;
  }
}

@mixin floatyBox {
  border: 1px solid transparent;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 2px 2px 3px rgb(170, 170, 170);
  display: flex;
  padding: 30px 30px 0px 20px;
}

@mixin statusBarSegment {
  border-top: 1px solid black;
  padding: 10px;
}

@mixin providerConfigStyle {
  .provider-config {
    @include font-defaults();
    width: 100%;
    &--head {
      overflow: hidden;
      position: sticky;
      display: flex;
      flex-direction: row;
      min-height: 100px;
      width: 100%;
      top: 90px;
      z-index: 5;
      background-color: white;
      box-shadow: 5px 0px 10px gray;
      &-message {
        margin-left: 50px;
        display: flex;
        align-items: center;
        width: 60%;
      }
      &-buttons {
        width: 30%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }
    &--aligner {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid black;
    }
    &--status {
      position: fixed;
      width: 300px;
      height: calc(100% - 241px);
      display: flex;
      flex-direction: column;
      &-title {
        @include statusBarSegment();
        font-size: 2em;
        margin-top: 25%;
      }
      &-description {
        @include statusBarSegment();
      }
      &-progress {
        @include statusBarSegment();
        &-description,
        &-displayDescription,
        &-openingTimes,
        &-socialMedia {
          display: flex;
          flex-direction: row;
          &-text {
            width: 85%;
          }
          &-check {
            width: 15%;
          }
          .check-mark-icon {
            height: 30px;
            width: 30px;
            color: green;
          }
          .exclamation-mark-icon {
            height: 30px;
            width: 30px;
            color: red;
          }
        }
      }
      &-buttons {
        @include statusBarSegment();
        &-preview {
          width: 100%;
          text-align: center;
          .preview-text {
            color: black;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .provider-config {
    @include providerConfigStyle;
  }
}
@media (max-width: 1024px) {
  .headline-profile-configuration {
    display: flex !important;
  }
  .provider-config {
    padding-top: 120px;
    @include providerConfigStyle;
    &--status {
      display: none !important;
    }
  }
}
@media (max-width: 1200px) {
  .provider-config {
    @include providerConfigStyle;
    .provider-config--body {
      border-left: 1px solid #000;
      width: 85%;
      padding-top: 50px;
      padding-right: 1%;
      margin-left: 300px;
      padding-left: 1%;
    }
  }
}

@media (min-width: 1201px) {
  .provider-config {
    @include providerConfigStyle;
    .provider-config--body {
      border-left: 1px solid #000;
      width: 85%;
      max-width: 1200px;
      padding-top: 50px;
      padding-right: 1%;
      margin-left: 300px;
      padding-left: 1%;
    }
  }
}

.wysiwyg-component {
  flex-direction: unset !important;
}
.date-status-edit {
  .popup-component--wrapper {
    z-index: 11;
  }
}
