.admin-activity-wrapper {
  width: 100%;
  max-width: 1500px !important;
  margin: 0 auto;
  .admin-activity--add {
    width: 100%;
    display: flex;
    gap: 20px;
    text-align: center;
    align-items: center;
    svg {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }
  .table-component {
    svg {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
    table {
      table-layout: fixed;
    }
    thead {
      td {
        font-weight: 400;
      }
      td:nth-child(1) {
        width: auto;
      }
      td:nth-child(2) {
        width: 120px;
      }
      td:nth-child(3) {
        width: auto;
      }
      td:nth-child(4) {
        width: auto;
      }
      td:nth-child(5) {
        width: 150px;
      }
      td:nth-child(6),
      td:nth-child(7),
      td:nth-child(8),
      td:nth-child(9),
      td:nth-child(10) {
        width: 100px;
      }
    }
    tbody {
      tr {
        td:nth-child(6),
        td:nth-child(7),
        td:nth-child(8),
        td:nth-child(9),
        td:nth-child(10) {
          text-align: center;
        }
      }
    }
  }
}

.copy-popup {
  &--header {
    display: flex;
    margin-bottom: 15px;

    .name {
      font-weight: 700;
      margin-right: 10px;
    }

    .type {
    }
  }

  &--name {
    font-weight: 700;
  }
}
