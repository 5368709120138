// Generated with util/create-component.js
@import "../../styles/typography.scss";

.wysiwyg-component {
  border: none;
  @include font-defaults;
  min-width: 100px;
  width: 100%;
  height: fit-content;
  position: relative;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &--input {
    box-shadow: 2px black;
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    min-width: 100px;
    min-height: 30px;
    width: 100%;
    height: 100%;
    text-align: left;
    background: transparent;

    &-toolbar {
      position: relative;
      box-sizing: border-box;
      margin: 0px;

      .rdw-embedded-modal-size,
      .rdw-image-modal-size {
        display: none;
      }
    }
    .editor-default {
      border-radius: 0px 0px 25px 25px;
      border: 1px solid;
      border-top: 0px;
    }
    .toolbar-default {
      border: 1px solid;
      border-radius: 15px 15px 0px 0px;
    }
    .editor-frameless {
      border-radius: 0px 0px 25px 25px;
      border: 1px solid transparent;
      border-top: 0px;
    }
    .toolbar-frameless {
      border: 1px solid #f4f4f4;
      border-radius: 15px 15px 0px 0px;
      border-bottom: 1px solid black;
    }
    &-content {
      position: relative;
      @include font-defaults;
      font-size: 16px !important;
      resize: none;
      overflow: hidden;
      overflow-y: scroll;
      padding: 20px;
      padding-left: 20px;
      padding-right: 20px;
      min-height: 120px;
      width: 100%;
      height: calc(100% - 45px);
      &:focus {
        outline: none;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 16px !important;
      }
      iframe {
        width: 100% !important;
        height: 350px;
        max-width: 400px !important;
      }
      img {
        width: 100% !important;
        max-width: 400px !important;
      }
      .rdw-image-center {
        justify-content: flex-start !important;
      }
      .rdw-link-decorator-wrapper img {
        display: none;
      }
      a {
        text-decoration: none;
        font-weight: bold;
        color: black;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &:enabled:hover {
      cursor: text;
    }

    &--color {
      display: flex;
      align-items: center;

      &-text {
        font-size: 1.2em;
      }
    }

    &-signCounter {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 6px 6px auto;
      padding-left: 6px;
    }
  }

  &--label {
    top: 1em;
    font-size: small;
    position: absolute;
    margin-left: 10%;
  }
}
