// Generated with util/create-component.js

@mixin arrowStyle {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  top: 50%;
  transform: translate(0, -50%);
}
@mixin slideShowStyle(
  $slideshowDirection: row,
  $slideshowDirectionReverse: row-reverse,
  $imageWidth: calc(61.5%),
  $imageHeight: 100%,
  $textWidth: calc(38.5%),
  $textHeight: 100%,
  $textSize: 10px
) {
  .slick-slideshow-component {
    max-height: 570px;
    &--content-item {
      display: flex !important;
      align-items: center;
      width: 100vw;
      height: fit-content;
      max-width: 880px;
      background-color: #9bd3e5;
      &-image-desktop {
        height: $imageHeight;
        width: $imageWidth;
        overflow: hidden;
        img {
          object-fit: contain;

          height: $imageHeight;
          width: 100%;
          max-width: 1920px;
          max-height: 570px;
        }
      }
      &-image-standalone {
        position: relative;
        width: 100% !important;
        .slick-slideshow-component--content-item {
          &-image-desktop {
            width: 100%;
            img {
              object-fit: contain;
            }
          }
          &-image-mobile {
            width: 100%;
            height: 100%;
            img {
              object-fit: contain;
            }
          }
          &-text {
            display: none;
            padding: 50px;
            padding-left: 70px;
            width: 100%;
            position: absolute;
            background: none !important;
          }
        }
      }
      &-image-mobile {
        height: $imageHeight;
        width: $imageWidth;
        overflow: hidden;
        img {
          object-fit: contain;
          width: 100%;
        }
      }
      &-text {
        box-sizing: border-box;
        padding: 20px 70px;
        width: $textWidth;
        height: $textHeight;
        background: #9bd3e5;
        font-size: $textSize;

        .button-component {
          margin: 0;
          padding: 0;

          > button {
            padding: 0;
            justify-content: left !important;
          }
        }
      }
    }
    .slick-slider {
      max-height: 570px;
      max-width: 1920px;
    }
    .slick-arrow {
      width: auto;
      height: auto;
      &::before {
        font-size: 25px;
        opacity: 1;
      }
    }
    .slick-next {
      right: 20px;
    }
    .slick-prev {
      left: 10px;
      z-index: 1;
    }
    .slick-dots {
      bottom: 15px;
      li {
        margin: 0px;
        button::before {
          font-size: 10px;
          color: white;
          opacity: 0.8;
        }
      }
      .slick-active {
        button::before {
          font-size: 15px;
          color: black;
          opacity: 0.8;
        }
      }
    }
    .content {
      &-normal {
        flex-direction: $slideshowDirection;
      }
      &-reverse {
        flex-direction: $slideshowDirectionReverse;
      }
    }
  }
  .full-image-slideshow-component {
    .image-gallery {
      img {
        object-fit: contain;
      }
      .fullscreen {
        img {
          height: 100% !important;
          object-fit: contain !important;
        }
      }
    }
    &--arrow-left,
    &--arrow-right {
      position: absolute;
      top: calc(50% - 25px);
      z-index: 2;
      cursor: pointer;
    }
    &--arrow-left {
      left: 25px;
    }
    &--arrow-right {
      right: 25px;
      transform: rotate(180deg);
    }
  }
}

@mixin slideShowDesktop($textWidth: calc(38.5%)) {
  @include slideShowStyle($textWidth: $textWidth);
  .slick-slideshow-component {
    &--content-item {
      &-image-mobile {
        display: none;
      }
    }

    .slick-slideshow-component--content-item .content-reverse {
      flex-direction: row-reverse !important;
    }
  }
}

@mixin slideShowMobile {
  @include slideShowStyle(
    $slideshowDirection: column,
    $slideshowDirectionReverse: column,
    $imageHeight: 43%,
    $textHeight: 57%,
    $textWidth: 100%,
    $imageWidth: 100%,
    $textSize: 15px
  );
  .slick-slideshow-component {
    .slick-slider {
      max-height: 1000px;
    }
    max-height: 1000px;
    &--content-item {
      max-height: 1000px;
      width: 100vw;
      &-image-desktop {
        display: none;
      }
      &-image-standalone {
        height: 100%;
      }
      &-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10vh 15vw;
        @media (max-width: 600px) {
          font-size: 10px;
        }
      }
      flex-direction: column;
    }
    .content {
      &-reverse {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 768px) {
  .slick-slideshow-component:not(.preview-desktop) {
    @include slideShowMobile();
  }
}

@media (min-width: 769px) {
  .slick-slideshow-component:not(.preview-mobile) {
    @include slideShowDesktop();
  }
}

.preview-desktop {
  @include slideShowDesktop($textWidth: 100%);
  .content-reverse {
    flex-direction: row-reverse !important;
  }
  .content-normal {
    flex-direction: row !important;
  }
}

.preview-mobile {
  @include slideShowMobile();
}

@media (min-width: 1920px) {
  .slick-slideshow-component:not(.preview-mobile) {
    @include slideShowStyle;
    .slick-slideshow-component {
      display: flex;
      justify-content: center;
    }
  }
}
