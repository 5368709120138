#provider-events-configuration-wrapper {
  .fixed-width {
    width: 160px;
  }

  .day-selector-wrapper {
    margin: 10px 0;
    justify-content: space-between;
  }

  .content-wrapper {
    margin-top: 20px;
    margin-bottom: 260px;
  }

  .headline {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .current-lunch-item-wrapper {
    margin: 5px 0;

    &--icon {
      height: 20px;
      width: 20px;
      cursor: pointer;
      color: red;
    }
  }
}

@media (max-width: 768px) {
  #provider-events-configuration-wrapper {
    .event-data-wrapper {
      .half-the-width {
        width: 100% !important;

        &.map-wrapper-container {
          display: none;
        }
      }
    }
  }
}
