@import "../../styles/typography.scss";

.map-wrapper {
  z-index: 0;
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 20px;
  &-marker {
    height: 50px;
  }
}

.leaflet-control-attribution {
  display: none;
}
