.delay-cancel--wrapper {
  .delay,
  .cancel,
  .soldout {
    &-wrapper {
      margin-bottom: 20px;
    }
  }
  .dropdown-component {
    margin-bottom: 15px;
  }
  .activity-date {
    height: 50px;
    .input-component {
      max-width: 150px !important;
    }
  }
  .input-component {
    height: auto;
    max-width: 60px !important;
    min-width: 60px !important;
    &--input {
      min-width: 60px !important;
    }
    * {
      font-size: large;
      padding: 0px;
    }
    &--input--underline {
      display: none;
    }
  }
  .table-component {
    td {
      height: auto;
    }
  }
}
