.progress-bar {
  width: calc(100% - 2px);
  margin: auto;
  height: 20px;
  border-radius: 15px;
  position: relative;
  background: gray;
  div {
    content: " ";
    height: 20px;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    animation: loading 2s ease-out normal forwards;

    @keyframes loading {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }
}
