.stock-image-slideshow-wrapper {
  * {
    border-radius: 25px;
  }
  .full-image-slideshow-component {
    max-width: 300px;
    margin: 0 auto;

    .image-gallery-image {
      cursor: pointer;
    }
  }
}
