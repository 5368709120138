@import "../../styles/typography.scss";

@mixin floatyBox {
  border: 1px solid transparent;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 2px 2px 3px rgb(170, 170, 170);
  display: flex;
  padding: 30px 30px 0px 20px;
}

@mixin providerConfigStyle {
  .provider-config--body {
    @include font-defaults();
    border-left: 1px solid black;
    width: 85%;
    padding-top: 50px;
    padding-right: 1%;
    margin-left: 300px;
    padding-left: 1%;
    &-base {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      justify-content: space-between;
      &-left {
        width: 45%;
        &--name {
          margin-bottom: 15px;
          * {
            margin-left: 0px;
            padding-left: 0px;
            min-width: 0px;
          }
        }
        &--location {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: 50px;
          &-street {
            width: 100%;
            height: 60px;
            * {
              margin-left: 0px;
              padding-left: 0px;
              min-width: 0px;
            }
          }
          &-zip {
            width: 40%;
            * {
              margin-left: 0px;
              padding-left: 0px;
              min-width: 0px;
            }
          }
          &-city {
            width: 60%;
            * {
              min-width: 0px;
              margin-left: 0px;
              padding-left: 0px;
            }
          }
        }
        &--displayDescription {
          margin-bottom: 50px;
          &-short {
            .textArea-component--content {
              min-height: 200px;
              height: 300px;
              font-size: 16px !important;
            }
          }
        }
      }
      &-right {
        margin-left: 5%;
        width: 50%;
        &-displayDescription {
          height: 400px;
          margin-bottom: 50px;
        }
        &--images {
          height: auto;
          padding: 2%;
        }
      }
    }
    &-detail {
      display: flex;
      flex-direction: row;
      gap: 2%;
      margin-bottom: 20px;
      justify-content: center;
      &--openingTimes {
        @include floatyBox();
        min-width: 650px;
        flex-direction: column;
        width: 99%;
        padding-bottom: 20px;
        height: fit-content;
        margin-bottom: 10px;

        &--content {
          flex-grow: 1;
          overflow: auto;
          padding-bottom: 25px;
        }
      }
      &--contact {
        @include floatyBox();
        display: flex;
        flex-direction: column;
        width: 99%;
        padding-bottom: 20px;
        &-publicMail,
        &-phoneNumber {
          margin-bottom: 20px;
          .input-component {
            height: 40px;
            &--input--underline {
              top: 2em;
            }
          }
        }
        &-socialMedia {
          &-facebook,
          &-instagram,
          &-linkedIn,
          &-whatsapp,
          &-youtube {
            .input-component {
              height: 40px;
              &--input--underline {
                top: 2em;
              }
            }
          }
        }
      }
      &--special-openingTimes {
        width: 100%;
      }
    }
    &-mobile-buttons {
      display: none;
    }
  }
}
@media (max-width: 768px) {
  .provider-config--body {
    @include providerConfigStyle;
    &-detail {
      flex-wrap: wrap;
      flex-grow: 1;
    }
  }
}
@media (max-width: 1024px) {
  .provider-config--body {
    @include providerConfigStyle;
    width: calc(100% - 20px) !important;
    margin-left: 0px !important;
    padding: 10px !important;
    &-base {
      flex-direction: column !important;
      &-left {
        width: 100% !important;
      }
      .provider-image-administration {
        min-height: 300px;
      }
    }
    &-detail {
      flex-wrap: wrap;
      flex-grow: 1;
      &--openingTimes {
        min-width: 0px !important;
      }
    }
    &-mobile-buttons {
      display: flex !important;
    }
  }
}
@media (max-width: 1200px) {
  .provider-config--body {
    @include providerConfigStyle;
    &-detail {
      flex-wrap: wrap;
      flex-grow: 1;
    }
  }
}

@media (min-width: 1201px) {
  .provider-config--body {
    @include providerConfigStyle;
    &-detail {
      padding-top: 20px;
      justify-content: space-around;

      &--openingTimes {
        width: 68% !important;
        justify-content: center;
        padding-right: 0px !important;
      }
      &--contact {
        width: 28% !important;
        height: 610px !important;
      }
    }
  }
}

.wysiwyg-component {
  flex-direction: unset !important;
}

.textArea-component--content {
  font-size: 16px !important;
}

.different-day-wrapper--item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  .input-component--input {
    padding-left: 10px;
  }

  &--add {
    margin-left: 20px;
    min-height: 20px;
    min-width: 20px;
    max-height: 20px;
    max-width: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;

      svg {
        path {
          fill: #e4e4e4;
        }
      }
    }
  }

  &--day {
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    width: 35px;
    max-width: 35px;
    min-width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;

    &:focus {
      outline: none;
      user-select: none;
    }

    &.active {
      background: black;
      color: white;
    }
  }
}

.time-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .input-component--input {
    padding-left: 10px;
  }

  &--item {
    align-items: center;
    display: flex;

    .seperator {
      margin: 0 10px;
    }
  }
  .input-component {
    width: 70px;
    text-align: center;
  }
  &--add {
    z-index: 1;
    svg {
      height: 20px;
      min-height: 20px;
      max-height: 20px;
      width: 20px;
      max-width: 20px;
      min-width: 20px;
      margin-left: 20px;
      cursor: pointer;
      z-index: 1;
    }

    &.disabled {
      svg {
        cursor: not-allowed;

        path {
          fill: #e4e4e4;
        }
      }
    }
  }
}

.day-of-week-selector-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .input-component--input {
    padding-left: 10px;
  }

  &--item {
    margin: 5px;
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    width: 35px;
    max-width: 35px;
    min-width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;

    &:focus {
      outline: none;
      user-select: none;
    }

    &.active {
      background: black;
      color: white;
    }
  }
}
