#mailbox-wrapper {
  display: flex;
  flex-direction: column;
  .mailbox-header {
    grid-area: header;
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    gap: 25px;

    > .button-component {
      width: unset;
      padding: 0;
    }

    &-link {
      align-self: center;
      padding: 0 10px;
      cursor: pointer;

      &.active {
        border-bottom: 1px solid black;
      }
    }
  }

  .mailbox-panel {
    display: grid;
    grid-template-areas: "list content";
    grid-template-columns: 35% auto;

    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 3px 6px #00000029;

    .mailbox-list {
      grid-area: list;
      height: 450px;

      overflow: auto;

      .mailbox-item {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 3px;
        cursor: pointer;
        padding: 3px 10px 3px 35px;
        background-color: #f8f8f8;

        &-open {
          top: 0;
          left: 0;
          position: absolute;
          height: 100%;
          width: 10px;
          background-color: black;
        }

        &.odd {
          background-color: #efefef;
        }

        &-header {
          display: flex;
          position: relative;
          flex-direction: row;
          justify-content: space-between;
        }

        &-status {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(-17px);
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: #9bd3e5;
        }

        &.unread {
          .mailbox-item-opposite {
            font-weight: bold;
          }
          .mailbox-item-subject {
            font-weight: bold;
          }
        }

        &-opposite {
          font-size: 1em;
        }

        &-time {
          font-size: 0.85em;
        }

        &-subject {
          font-size: 0.9em;
        }

        &-message {
          font-size: 0.9em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-break: normal;
          width: 100%;
        }
      }
    }

    .mailbox-content {
      grid-area: content;
      height: 450px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-message {
        display: flex;
        flex-direction: column;
        gap: 15px;

        overflow: auto;

        height: 100%;

        padding: 25px 25px 25px 0;

        &-entry {
          display: flex;
          flex-direction: column;
          gap: 3px;
          padding-left: 25px;
          border-bottom: 3px solid #9bd3e5;
          padding-bottom: 15px;
        }

        &-entry:last-of-type {
          border-bottom: none;
          padding-bottom: 0;
        }

        &-time {
          right: 0;
          top: 0;
          position: absolute;
          color: #7e7e7e;
          font-size: 0.9em;
          font-weight: normal;
        }

        &-sender {
          font-size: 1.1em;
          font-weight: bold;
        }

        &-subject {
          position: relative;
          font-size: 0.9em;
          font-weight: 500;
        }

        &-text {
          padding-top: 10px;
          font-size: 0.9em;
        }
      }

      &-footer {
        display: flex;
        flex-direction: row;
        gap: 30px;
        padding: 10px 25px;
        box-shadow: 3px 0px 6px #00000029;

        &-action {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }
      }
    }

    .mailbox-create {
      grid-area: list / list / content / content;
    }
  }

  .mailbox-create {
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: 20px;

    &-header {
      display: flex;
      justify-content: space-between;

      &-title {
        font-weight: bold;
      }

      &-close {
        cursor: pointer;
      }
    }

    .dropdown-component {
      margin: 0;
      max-width: 300px;
    }

    .input-component {
      max-width: 600px;
      padding: 0;

      .input-component--input-signCounter {
        transform: translateY(8px);
        font-size: 0.9em;
      }
    }

    .textArea-component {
      .textArea-component--content {
        background-color: #f4f4f4 !important;
        border: 0;
      }
    }

    .button-component {
      max-width: 300px;
      padding: 0;
    }
  }

  .popup-component--wrapper {
    .popup-component--wrapper-content-wrapper {
      height: fit-content;
      width: fit-content;

      .mailbox-create {
        padding: 0;
        width: 500px;
      }
    }
  }

  .mailbox-create {
    .textArea-component {
      padding: 0;
    }

    .textArea-component--content {
      margin: 0;
    }
  }
}
