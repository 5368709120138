@import "../styles/typography.scss";

.scroll-box {
  scroll-margin-top: 250px;
}

@mixin activityPopup {
  .popup-component--wrapper {
    h3 {
      margin-bottom: 30px;
    }
    .button-component {
      margin: auto !important;
    }
    &-content-wrapper {
      height: 100%;
      .content {
        overflow: hidden;
      }
    }
  }
}

.activity-edit-load-popup {
  @include activityPopup();
}
.progress-popup {
  .popup-component--wrapper-content-wrapper {
    height: fit-content;
    .title-close {
      display: none;
    }
  }

  .progress-text {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      width: fit-content;
    }
  }
}
.event-edit-box {
  position: relative;
  width: 90%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;

  &--counter {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;

    &.selected-event-box {
      background: black;
      color: white;
    }

    &.everything-good {
      border: 2px solid #8bd39b;
      background-color: #8bd39b;

      svg {
        height: 80%;

        path {
          fill: white;
        }
      }
    }
  }

  &--content {
    margin-left: 15px;
    border-radius: 30px;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid black;
    background-color: white;

    &.everything-good {
      border: 2px solid #8bd39b;
      background-color: #d7ead7;
    }

    .unselected-content {
      height: 120px;
      overflow: hidden;
      p {
        text-align: start;
        margin-left: 20px;
        margin-top: 8px;
        color: #a0a0a0;
        margin-bottom: 10px;
      }
      h2 {
        padding: 10px !important;
        text-align: start;
      }
      .openingtimes-result-entry {
        p {
          font-weight: bold;
          color: black;

          &.value-item {
            margin-top: -12px;
            font-weight: normal;
          }
        }
      }
    }
    .unselected-replacement {
      svg {
        height: 40px;
        width: 40px;
      }
      text-align: start;
      p {
        padding: 0px !important;
        display: flex;
      }
      &-multiwrapper {
        margin-top: -5px;
        p {
          display: flex;
          margin: 5px 0px;
          padding-left: 10px !important;
          height: 20px;
          align-items: center;
        }
      }
    }

    .category-selector-wrapper {
      margin: 20px 0;
      display: flex;
      align-items: flex-start;
      &--input {
        margin-right: 10px;

        input {
          accent-color: black;
          outline: none;
        }
      }
      &--text {
        &-header {
          font-weight: bold;
        }
      }
    }
  }
}

.selected-content {
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
  @include activityPopup();
  .input-label {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: start;
    display: flex;
    flex-direction: row;
    .required {
      font-size: small !important;
      color: red;
    }
  }

  .input-component {
    max-width: 400px;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    &--input {
      min-width: auto;
      padding-left: 0px;
      &--underline {
        top: 45px;
        margin-left: 0px;
        width: calc(100% - 20px);
      }
    }
  }
  .incomplete-flag {
    animation: pulse 2s infinite;
    box-shadow: 0 0 0 0 rgba(255, 2, 2, 1);
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 2, 2, 0.7);
    }
    80% {
      box-shadow: 0 0 10px 10px rgba(255, 2, 2, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 2, 2, 0);
    }
  }
  //Styling for Step1
  .name-input {
    width: calc(100% - 20px);
    margin-left: 10px;
    .input-component {
      max-width: 100%;
      &--input-signCounter {
        bottom: 12px;
      }
      &--input--underline {
        width: calc(100% - 20px) !important;
      }
    }
  }
  .description-input {
    width: calc(100% - 20px);
    margin-left: 10px;
  }
  &--image-select {
    display: flex;
    gap: 10px;
    position: relative;
    p {
      color: #6e6e6e;
    }
    .image-upload {
      h3 {
        width: 50px;
      }
      width: calc(50% - 35px);
      .slideshow-item-upload-wrapper {
        margin-bottom: 0px;
      }
      .image-crop-wrapper {
        max-width: 1000px;
      }

      .slideshow-item-upload-wrapper--text {
        color: #ed6947;
      }
    }
    .stock-image {
      width: calc(50% - 35px);
      .stock-image-slideshow-wrapper {
        width: inherit;
        position: absolute;
        bottom: 0px;
      }
      .image-gallery-bullets-container {
        display: none;
      }
    }
  }
  &--location {
    margin: 10px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 400px;
    &-manualInput {
      overflow: hidden;
      .input-component {
        margin-left: 10px;
      }
    }
  }
  &--downloads {
    margin: 40px 0px;
    &-upload {
      display: flex;
      align-items: center;
      p {
        margin-left: 10px;
      }
      .file-input {
        &-component {
          display: flex;
          flex-direction: row;

          &--item-wrapper {
            display: flex;
            align-items: center;
            &-item {
              gap: 20px;
            }
          }
          .button-component {
            margin: auto;
            min-width: 20px;
            min-height: 20px;
            width: 20px;
            height: 20px;
            padding: 0px;
            .button-component--button {
              min-height: 20px !important;
              height: 20px;
              padding: 0px;
              color: white !important;
              background-color: black !important;
            }
          }
        }
      }
    }
  }
  .checkbox-component {
    margin: 20px;
    margin-left: 5px;
  }
  // Styling for Step2
  .main-tag-selection {
    max-width: 1000px;
    width: calc(100% - 20px);
    margin: 10px;
    padding: 10px;
    margin-bottom: 30px;
    .search-component {
      max-width: 600px;
    }
  }
  .category-selection {
    max-width: 1000px;
    .category-selector-wrapper {
      &--input {
        gap: 20px;
        display: flex;
        flex-direction: row;
      }
    }
    margin-bottom: 30px;
  }
  .target-audience--selection {
    max-width: 1000px;
    .tag-component {
      display: flex;
      flex-direction: row !important;
      &--tag {
        width: fit-content;
      }
    }
    margin-bottom: 30px;
  }
  .detail-tag--selection {
    max-width: 1000px;
    width: fit-content;
    margin: 10px;
    margin-bottom: 30px;
  }
  .no-detail-tag--selection {
    max-width: 1000px;
    width: fit-content;
    margin: 10px;
    .selection {
      display: flex;
      flex-direction: row;
      @media (max-width: 1000px) {
        align-items: center;
        flex-direction: column;
        .input-component {
          padding: 0px;
          width: 100%;
        }
      }
      .input-component {
        input {
          padding-left: 10px;
        }
      }
      .button-component {
        height: 60px !important;
        padding: 0px;
        margin: 10px !important;
        max-width: 400px;
      }
    }
    .table {
      &-tags {
        width: 245px;
        display: flex;
        flex-direction: row;
        margin: 5px;
        gap: 20px;
        p {
          width: 200px;
        }
        svg {
          cursor: pointer;
          height: 25px;
          width: 25px;
        }
      }
    }
  }
  // Styling for Step3
  &--singleDate {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  &--date-table {
    .event-icons {
      svg {
        height: 20px;
        width: 20px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  &--dateLink {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  &--dateRepetition {
    display: flex;
    &-inputLabel {
      width: fit-content;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    &-detail {
      width: 100%;
      border-left: 1px solid black;
      margin-left: auto;
      padding-left: 20px;
      &--startDate,
      &--intervall,
      &--repetition,
      &--endDate {
        min-width: 300px;
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
          height: auto;
          text-align: start;
        }
        .input-component {
          width: 200px;
          height: 60px;
          &--input {
            text-align: center;
            &--underline {
              top: 2.6em;
            }
          }
        }
      }
      &--repetition,
      &--intervall {
        .input-component {
          margin: 5px;
          min-width: 50px;
          width: 50px;
          &--input {
            min-width: 50px;
            width: 50px;
            text-align: center;
            &--underline {
              width: 50px;
            }
          }
        }
        &-days {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          &-select {
            display: flex;
            flex-direction: row;
            .checkbox-component {
              flex-direction: column;
              margin: 5px;
              &--label {
                margin: 0px;
              }
            }
          }
        }
      }
      &--months,
      &--years {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
          width: fit-content;
        }
        .checkbox-component {
          margin: 10px 0px;
          &--label {
            display: none;
          }
        }
        .input-component {
          margin: 5px;
          min-width: 50px;
          width: 50px;
          &--input {
            min-width: 50px;
            width: 50px;
            text-align: center;
            &--underline {
              width: 50px;
            }
          }
        }
      }
    }
    &-select {
      .checkbox-component {
        margin: 20px 0px;
      }
    }
  }
  &--dateEdit {
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
    }
    &-date {
      width: auto;
    }
    &-inputLabel {
      width: 100px;
      font-weight: bold;
    }
    &-timeFrame {
      width: auto;
      display: flex;
      flex-direction: column;
      &-inputs {
        display: flex;
        align-items: center;
        .input-component {
          width: 70px;
        }
      }
    }
  }
  &--date-buttons {
    display: flex;
    flex-wrap: wrap;
    .button-component {
      max-width: 250px;
    }
  }
  &--single-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    .input-component {
      max-width: 80px;
      &--input {
        text-align: center;
        &--underline {
          display: none;
        }
      }
    }
  }
  &--deadline-input {
    display: flex;
    align-items: center;
    &--left-side {
      border-right: 2px solid black;
    }
    &--right-side {
      margin-left: 20px;
    }
  }
  &--showDate-table {
    width: 100%;
    max-width: 500px;
    overflow: scroll;
    .table-component {
      &--table {
        min-width: 300px;
      }
      td {
        padding: 0px;
      }
      .input-component {
        width: 150px;
        &--input {
          text-align: center;
          &--underline {
            width: 130px;
          }
        }
      }
    }
  }
  &--intervall-amount-selector {
    display: flex;
    align-items: center;
    .input-component {
      width: 70px;
      min-width: 70px;
      margin-right: 10px;
      &--input {
        min-width: 70px;
        text-align: center;
      }
    }
  }
  &--show-date {
    display: flex;
    flex-wrap: wrap;
    &--input {
      display: flex;
      align-items: center;
      width: auto;
      .input-component {
        width: 200px;
        &--input {
          text-align: center;
        }
      }
    }
    &-stop {
      .table-component {
        thead {
          tr {
            td:nth-child(1),
            td:nth-child(2) {
              max-width: 150px;
              width: 150px;
            }
          }
        }
        td {
          padding: 0px;
          p {
            text-align: start;
          }
          .input-component {
            height: 60px;
          }
        }
      }
    }
  }
  //Styling für Step4
  .discount-detail-input {
    margin: 10px;
    margin-right: 40px;
    padding-right: 5px;
    .textArea-component {
      width: 100% !important;
      max-width: 650px;
      width: auto;
    }
  }
  .discount-link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .input-component {
      width: 350px;
    }
    &--check {
      font-size: 20px;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: initial;
    }
  }
  &--ticket-type,
  &--single-link {
    display: flex;
    flex-wrap: wrap;
  }
  &--ticket-link {
    &-input {
      max-width: calc(100% - 20px);
      width: fit-content;
      margin-left: 10px;
    }
    &-list {
      .table-component {
        max-width: 600px;
        thead {
          tr {
            td:nth-child(1),
            td:nth-child(2) {
              max-width: 150px;
              width: 150px;
            }
          }
        }
        td {
          p {
            text-align: start;
          }
          padding: 0px;
          .input-component {
            max-width: 300px;
            height: 60px;
          }
        }
      }
    }
  }
  &--discount-type {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    .checkbox-component {
      width: fit-content;
    }
  }
  &--discount-input {
    align-items: center;
    display: flex;
    width: fit-content;
    margin-left: 10px;
    .input-component {
      width: 100px;
      margin-right: 20px;
      &--input {
        text-align: center;
      }
    }
  }
  &--ticket-price {
    .segment-grouper {
      display: flex;
      align-items: center;
    }
    &-button {
      max-width: fit-content;
      width: auto;
      border: 0px;
      border-radius: 50px;
      padding: 5px;
      margin-left: 10px;
      .button-component {
        margin-left: 0px !important;
      }
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .input-component {
      width: fit-content;
      max-width: 100px;
      &--input {
        text-align: center;
        &--underline {
          width: 100px;
        }
      }
    }
    .button-component {
      width: 50px;
      height: 50px;
      padding: 0px;
      &--button {
        background-color: black !important;
        color: white !important;
      }
    }
  }
  &--price-list {
    svg {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      cursor: pointer;
    }
    .table-component {
      max-width: 300px;
      &--table {
        min-width: 0px;
      }
      thead {
        tr {
          td:nth-child(1) {
            max-width: 150px;
            width: 150px;
          }
          td:nth-child(2) {
            max-width: 100px;
            width: 100px;
          }
        }
      }
      td {
        p {
          text-align: start;
        }
      }
    }
  }
  &--register {
    display: flex;
    flex-direction: row;
    .checkbox-component {
      margin: 0px;
    }
    &-stop {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      .input-component {
        &--input {
          padding-left: 0px;
          &--underline {
            margin-left: 0px;
          }
        }
        width: 200px;
      }
    }
  }
  &--vvk-input {
    .dropdown-component {
      max-width: 500px;
    }
    .button-component {
      max-width: 300px;
    }
    .table-component {
      td {
        p {
          text-align: start;
        }
      }
      svg {
        height: 20px;
        width: 20px;
      }
    }
    &-button {
      width: fit-content;
      max-width: calc(100% - 20px);
      margin-left: 10px;
    }
  }
  &--show-date {
    display: flex;
    flex-wrap: wrap;
    &--input {
      display: flex;
      width: auto;
    }
    &-stop {
      .table-component {
        &--table {
          min-width: 0px;
          width: auto;
        }
        thead {
          tr {
            td {
              max-width: 150px;
              width: 150px;
            }
          }
        }
        td {
          p {
            text-align: start;
          }
          .input-component {
            height: 60px;
            &--input--underline {
              top: 45px;
            }
          }
        }
      }
    }
  }
}

.new-event-edit {
  &-wrapper {
    overflow-y: auto;
    @include font-defaults;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    min-height: calc(100%);
    height: calc(100%);
    width: 100%;
    box-sizing: border-box;
    .desktop-header {
      padding: 40px 80px;
      h1 {
        font-weight: 300;
        font-size: 40px;
      }
    }
    &--top {
      box-sizing: border-box;
      width: 100%;
      height: calc(100%);
      flex-grow: 1;
      display: flex;
      .mobile-wrapper {
        text-align: center;
        h2 {
          font-size: 19px;
          padding: 20px;
        }
        overflow-y: auto;
        min-width: 300px;
        max-width: 400px;
        width: 100%;
        max-height: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .header {
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          &--header {
            font-size: 20px;
            font-weight: normal;
          }

          svg {
            width: 90%;
            margin: 0 auto;
            max-width: 140px;
          }

          &--title {
            font-size: 20px;
            width: 70%;
            margin: 0 auto;
            font-weight: bold;
            text-align: center;
          }

          &--text {
            font-size: 20px;
            width: 70%;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: center;
          }
        }

        .content {
          padding-bottom: 20px;
          flex-grow: 1;

          .agb-wrapper {
            display: flex;
            align-items: center;
            width: 90%;
            margin: 0 auto;

            a {
              color: black;
            }

            .checkbox-component {
              margin-right: 0;

              &--label {
                width: 0;
                padding: 0;
                margin: 0;
              }
            }
          }

          .button-component {
            margin: 0 auto;
            max-width: 300px;
          }
        }
      }
      .desktop-wrapper {
        &--content {
          .header {
            width: 100%;
          }
        }
        position: relative;
        .content-wrapper-aligner {
          margin-left: 40px;
          margin-top: 20px;
          width: calc(100% - 40px);
          height: calc(100% - 100px);
          overflow: scroll;
        }
        width: 100%;
        padding-right: 10px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        &--content {
          max-width: calc(100% - 60px);
          min-width: 300px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
        .event-step-buttons {
          max-width: calc(100% - 60px);
          position: absolute;
          bottom: 0px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          .button-component {
            width: 200px;
            margin: 10px;
            padding: 10px;
          }
        }

        .desktop-menu-button-wrapper {
          margin-bottom: 30px;
          display: flex;
        }

        .header {
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          &--header {
            font-size: 20px;
            font-weight: normal;
          }

          svg {
            width: 90%;
            margin: 0 auto;
            max-width: 140px;
          }

          &--title {
            font-size: 20px;
            width: 70%;
            margin: 0 auto;
            font-weight: bold;
            text-align: center;
          }

          &--text {
            font-size: 20px;
            width: 70%;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: center;
          }
        }

        .category-selector-wrapper {
          margin: 20px 0;
          display: flex;
          align-items: flex-start;
          &--input {
            margin-right: 10px;

            input {
              accent-color: black;
              outline: none;
            }
          }
          &--text {
            &-header {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .new-event-edit-wrapper--top {
    height: calc(100vh - 363px);
    overflow: hidden;
  }
  .desktop-wrapper {
    overflow: scroll;
  }
}
@media (max-width: 768px) {
  .checkbox-component {
    .checkmark {
      margin-top: 2px;
      margin-left: 2px;
    }
  }
  .new-event-edit-wrapper {
    .desktop-header {
      display: none;
    }
    .desktop-wrapper {
      display: none;
    }
    .mobile-wrapper {
      max-width: 768px;
      .content {
        padding-top: 30px;
      }
      .header {
        &--title,
        &--text {
          font-size: 15px;
          padding: 0px;
          margin: 10px 0px;
          width: auto;
        }
      }
    }
  }
  .selected-content {
    padding-left: 10px;
    .event-date-edit {
      p {
        font-weight: bold;
        padding: 0px;
      }
      .checkbox-component {
        .checkmark {
          margin-top: 0px;
          margin-left: 0px;
        }
      }
    }
    .input-label {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: start;
      padding-left: 20px;
      font-weight: bold;
    }
    &--location {
      .flex-it {
        flex-direction: column;
      }
    }
    &--single-time {
      justify-content: left;
      align-items: center;
      .input-component--input {
        width: 80px !important;
        padding-left: 0px;
      }
      p {
        margin-left: -20px;
        margin-right: 0px;
      }
    }
    &--singleDate,
    &--dateLink {
      flex-wrap: wrap;
    }
    &--dateRepetition {
      flex-direction: column;
      &-detail {
        border: none;
        padding: 0px;
        &--startDate,
        &--repetition,
        &--endDate,
        &--intervall {
          min-width: 0px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid gray;
          .input-component {
            &--input {
              padding: 0px;
              &--underline {
                margin: 0px;
              }
            }
          }
        }
        &--repetition,
        &--weeks,
        &--months,
        &--years {
          margin-bottom: 10px;
          padding-bottom: 10px;
          .input-component--input {
            width: 40px;
            min-width: 40px;
            &--underline {
              width: 40px;
            }
            .input-component--input {
              padding: 0px;
            }
          }
        }
        &--months {
          p {
            margin-left: 10px;
          }
          &-borderBottom {
            border-bottom: 1px solid gray;
          }
        }
      }
    }
    &--deadline-input {
      flex-direction: column;
      &--left-side {
        border: none;
      }
    }
  }
}

@media (max-width: 1000px) {
  .desktop-wrapper {
    .content-wrapper-aligner {
      margin-left: 20px;
      height: calc(100% - 180px) !important;
    }
  }
  .selected-content {
    &--image-select {
      flex-direction: column;
      h3 {
        width: 100%;
        text-align: center;
      }
      p {
        text-align: center;
      }
      .image-upload {
        width: 100%;
        .file-input-component {
          width: fit-content;
        }
      }
      .stock-image {
        width: 100%;
        .stock-image-slideshow-wrapper {
          position: relative;
        }
      }
    }
  }
}
