.special-note-edit {
  .popup-component--wrapper-content-wrapper {
    height: fit-content;
  }
  .flex-it-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    svg {
      height: 20px;
      cursor: pointer;
    }
    .red-inside {
      color: red;
      height: 20px;
    }
  }
  .time-frame-edit {
    display: flex;
    width: 100%;
    gap: 20px;
    .input-component {
      width: calc(50% - 10px);
      max-width: 250px;
    }
  }
  .input-component--input {
    width: calc(100% - 20px);
    &-signCounter-value {
      top: -15px;
    }
  }
  h3 {
    margin: 20px 0px 0px 10px;
    font-size: x-large;
  }
  &--description {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 769px) {
      flex-direction: row;
    }
    gap: 10px;
    &-text {
      font-size: normal;
      @media (min-width: 769px) {
        width: calc(50% - 10px);
      }
    }
    &-image {
      img {
        height: 150px;
      }
      @media (min-width: 769px) {
        width: calc(50% - 10px);
      }
    }
    padding: 20px;
  }
  .button-component {
    max-width: 400px;
  }
  &-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
