.click-box-wrapper {
  height: 100px;
  display: flex;
  box-shadow: 2px 2px 4px #00000029;
  border-radius: 30px;
  padding: 10px 20px;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  &--item {
    display: flex;
    align-items: center;

    svg {
      height: 30px;
      width: 30px;
    }

    &-first-icon {
      margin-right: 10px;
    }

    &-chevron {
      background: black;
      color: white;
      border-radius: 50%;

      .cls-1 {
        stroke: white;
      }
    }

    &-text {
      font-size: 1.2em;
      flex-grow: 1;
    }
  }
}
