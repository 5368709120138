$font-family: "DM Sans", "Avenir Next", Helvetica, Arial, sans-serif;

@mixin font-defaults {
  font-family: $font-family !important;
}

@mixin heading {
  @include font-defaults;

  font-size: 40px;
  font-weight: bold;
}
