.slideshow-item-to-edit-wrapper {
  background: #f4f4f4;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 20px;

  &--flex-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .input-component--input-signCounter {
      bottom: -2px;
      * {
        background: transparent !important;
      }
    }

    .button-component {
      width: 45%;
    }

    &--image {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: lightgray;
      padding: 4px;
      box-sizing: border-box;
      border-radius: 25px;

      &--icon {
        width: 90% !important;
        max-width: 60px;
        height: 90% !important;
        max-height: 60px;
      }

      img {
        width: 100%;
        border-radius: 25px;
      }
    }

    &--item {
      min-width: min-content;
      box-sizing: border-box;
      width: 45%;
      margin-bottom: 20px;

      .input-component--input input {
        font-size: 13px;
      }

      &--flex-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .input-component {
          width: 45%;
          min-width: 0px;
        }
      }
    }
  }
}

.download-template-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;

  .download-template {
    margin-right: 20px;

    a {
      color: black;

      &:hover {
        color: #3d3d3d;
      }

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.a-little-bit-margin-top {
  margin-top: 20px;
}

.slideshow-item-upload-wrapper {
  background: #dbdbdb;
  border-radius: 25px;
  padding: 0px 5px;
  box-sizing: border-box;
  height: 200px;
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;

  svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: "contain";
    border-radius: 25px;
  }

  &--icon {
    width: 30px;
    height: 30px;
  }
}

input {
  font-size: 1em;
}

.slideshow-item-to-edit-input-file-field {
  display: none;
}

@media (max-width: 768px) {
  .slideshow-item-to-edit-wrapper--flex-box--item {
    width: 100%;
  }
}
